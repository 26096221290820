<template lang='pug'>
.container
  // QRCode 進入畫面
  //- transition(name='fade')
  .black(v-show='bBlink')
  .phone
    div(style='padding: 14px;')
      h3 請保持電話螢幕亮起
      h3 當有電話來電時，請按下接聽
      h3 通話完成後請回到電腦前
      span(v-show='onCall')
        .bottom.clearfix
        el-button.button(v-show='!bTalking' type='primary' @click='playVoice' icon='el-icon-phone-outline' circle)
        el-button.button(v-show='bTalking' type='danger' icon='el-icon-phone' circle)
  el-dialog.info-dialog(title='說明事項' :visible.sync="infoVisible")
    .info
      h3 請開啟您的手機音量
      h3 並保持電話螢幕亮起
    el-button(@click='start') 確認
</template>

<script>

export default {
  name: 'Entry',
  components: {
  },
  data() {
    return {
      id: 0,
      infoVisible: true,
      onCall: false,
      music: null,
      sound: null,
      bTalking: false,
      part: 1,
      bClick: false,
      bBlink: false,
    };
  },
  computed: {
  },
  created() {
    this.id = this.$route.query.id;
    console.log('this.id :>> ', this.id);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:4040', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });

    this.socket.on('onCall1', (data) => {
      console.log('onCall 1', data);
      if (data === this.id){
        this.music.src = '/music/ring.wav';
        this.music.loop = true;
        this.music.play();
        this.onCall = true;
        this.part = 1;
        this.bClick = true;
        this.bTalking = false;
        this.blinkTimer = setInterval(() => {
          this.bBlink = !this.bBlink;
        }, 100);
      }
    });

    this.socket.on('onCall2', (data) => {
      console.log('onCall 2', data);
      if (data === this.id){
        this.music.src = '/music/ring.wav';
        this.music.loop = true;
        this.music.play();
        this.onCall = true;
        this.part = 2;
        this.bClick = true;
        this.bTalking = false;
        this.blinkTimer = setInterval(() => {
          this.bBlink = !this.bBlink;
        }, 100);
      }
    });

    this.music = new Audio();
    this.music.pause();
  },

  methods: {
    start() {
      this.infoVisible = false;
      this.noSleep();
    },
    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },
    playVoice() {
      if (this.bClick){
        clearInterval(this.blinkTimer);
        this.bBlink = false;
        this.bTalking = true;
        this.bClick = false;
        if (this.part === 1){
          this.music.pause();
          this.music.currentTime = 0;
          this.playSound('/sound/mobile/段落四共時性.wav');
          setTimeout(() => {
            this.socket.emit('endCall1', this.id);
            this.onCall = false;
            this.bTalking = false;
          }, 55000);
        }

        if (this.part === 2){
          this.bTalking = true;
          this.music.pause();
          this.music.currentTime = 0;
          this.playSound('/sound/mobile/段落五之二媽媽電話.wav');
          setTimeout(() => {
            this.socket.emit('endCall2', this.id);
            this.onCall = false;
            this.bTalking = false;
          }, 50000);
        }
      }
    },
    noSleep() {
      const noSleep = new this.$NoSleep();
      document.addEventListener('click',
        function enableNoSleep() {
          noSleep.enable();
          document.removeEventListener('click', enableNoSleep, false);
        },
        false);
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  // background-image: url('/img/bg.jpeg');
  background-color: white;
  height: 100vh;
  width: 100vw;
  .button{
    // position: fixed;
    font-size: 50px;
    z-index: 99999;
  }
  .phone {
    padding-top: 40vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    z-index: 99999;
  }

  .black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 9999;
    pointer-events: none;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .05s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
