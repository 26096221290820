<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 檢察官
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}
    .popImage(v-show='bImageVisible')
      img(src="/img/刀子（圖）.png")

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      bImageVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['你的電話響了...'],
      bText: false,
      timer: null,
      mobileTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['不是，師父不會殺人...', '我看到師父，但師父一下子就不見了，平常我把身體借給師父的時候，我都不太有印象...', ''],
        ['我記不清楚了', '不是我，是一個黑暗的力量...', ''],
        ['師父真得存在，如果你遇過他，你就會相信了...', '有光明就有黑暗，黑暗的勢力一直都在，只是我們常常視而不見', ''],
        ['你難道不會懷疑，這世界上就是有科學沒有辦法解決的事情？', '生死有命，就看你相不相信罷了', ''],
        ['我一直都是實話實說，但你從來不相信。你不相信我說的一切，反覆問我同樣的問題，你已經預設的結果，只是要我填空而已。', '法官會明理的，我相信法官知道發生了什麼事。只要法官不受到社會輿論的壓力。', ''],
        ['我沒有生病，我是天選之人，只是比較特別沒有生病。', '這發生的一切都是向善的，人各有命，我們不能強求。人類的律法是沒有辦法管束我的。', ''],
        ['', '', ''], // 9
        ['之前不是說過了，就是朋友...本來有人來問事，我們就會當家人，宮裡大家都是一家人...', '他常常最後一個問事。我退駕後，他常常還在，就會一起去吃宵夜...大概就這樣。', ''],
        ['什麼意思！飯可以亂吃，話不可以亂說，沒有證據不要在那邊...', '嚴格來說，他準備要開始修煉了，從最基本的開始。每個人資質不同，但都可以修，好好修，可以漸漸知道師父的意思...', ''], // 10
        ['就去調啊！你們找不到任何證據的。', '就是比較常一起吃飯而已，還是你說借錢，之前借的錢都已經還了啊！', ''],
        ['陪他去散散心而已，有些問題自己要想通，師父怎麼幫忙也有限，也來辦事好一陣子了，一起出去走走還好吧！', '我姐這樣說？...那不然你說說，你現在在幻想哪套劇本？！', ''],
        ['那有什麼好說的，就已經還了啊！你們警察想像力真豐富，白的都可以說成黑的。', '之前廟裡比較缺，就問他有沒有辦法，他手頭也還可以，就借了。', ''],
        ['你現在在鬼扯什麼！那裡街坊鄰居住這麼近，哪戶傳出來的還不知道呢！...師父跟我說你上個禮拜不知道跟誰去Hotel...', '那個打電話的，想像力真得很豐富...', ''],
        ['你要是再問一次相關的問題，師父說就不要回答了。', '我覺得我還有看到別的...恩，你帶去Hotel的對象好像不只一個...', ''], // 15
        ['', '', ''],
        ['', '', ''],
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:4040', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });

    this.socket.on('endCall2', (data) => {
      console.log('endCall2', data);
      if (data === this.id){
        clearTimeout(this.mobileTimer);
        this.step = 3;
        this.textArray.push('你說的師父叫你去殺人？');
        this.checkPlayVideo();
      }
    });

    this.startVisible = true;

    // 入場
    const src = '/video/檢察官3-1.mp4';
    this.playVideo(src);
    this.playSound('/sound/檢察官3-1.wav');

    // 背景BGM重複播放
    this.music = new Audio();
    this.music.pause();
    this.music.src = '/music/檢察官BGM.wav';
    this.music.loop = true;
    this.music.play();

    // 延遲出現對話框
    setTimeout(() => {
      this.dVisible = true;
      this.bText = true;
      this.step = 1;
      this.socket.emit('onCall2', this.id);
      this.mobileTimer = setTimeout(() => {
        this.step = 3;
        this.textArray.push('你說的師父叫你去殺人？');
        this.checkPlayVideo();
      }, 80000);
    }, 2000);

    // setTimeout(() => {
    //   this.step = 3;
    //   this.textArray.push('你說的師父叫你去殺人？');
    //   this.checkPlayVideo();
    // }, 5000);

    setInterval(() => {
      // console.log('Check!');
      this.$nextTick(() => {
        this.scrollDown();
      });
    }, 500);
  },
  destroyed() {
    this.socket.disconnect();
  },

  methods: {
    onTextComplete() {
      this.questionVisible = true;
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 9) {
        setTimeout(() => {
          this.step = 10;
          this.textArray.push('聽廟裡的説，你跟死者很常一起吃飯、喝酒，聽起來好像不只有宮廟的關係...');
          this.checkPlayVideo();
        }, 5000);
      }

      if (this.step === 17) {
        console.log('END :>> ');
        setTimeout(() => {
          this.music.pause();
          this.$router.push({ path: `/cp5`, query: { id: this.id, passcode: this.passcode }});
        }, 6000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
      // this.dVisible = true;
      // if (this.step === 10) { // CCTV 影像後
      //   this.step = 11;
      //   this.textArray.push('這是我們調閱巷口的監視器，昨天晚上10點38分，你到對方家中幹什麼？');
      //   this.checkPlayVideo();
      // }
      // if (this.step === 26) { // CCTV 影像後
      //   this.step = 27;
      //   this.textArray.push('那個聲音是誰？不是你嗎？');
      //   this.checkPlayVideo();
      // }
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          this.Ans(['NULL', 'NULL', 'NULL']
            , 2, '▲電話響[母親來電]'
            , 2, '▲電話響[母親來電]'
            , 0, '');
          break;
        case 2:
          this.Ans(['NULL', 'NULL', 'NULL']
            , 3, '你說的師父叫你去殺人？'
            , 3, '你說的師父叫你去殺人？'
            , 0, '');
          break;
        case 3:
          this.Ans(['不是，師父不會殺人...', '我看到師父，但師父一下子就不見了，平常我把身體借給師父的時候，我都不太有印象...', 'NULL']
            , 4, '如果不是師父？那是你？'
            , 5, '你怎麼確定這不是你的幻想？'
            , 0, '');
          break;
        case 4:
          this.Ans(['我記不清楚了', '不是我，是一個黑暗的力量...', 'NULL']
            , 6, '你說的師父存不存在，我不能確定...我們講求的是科學證據，兇器上的指紋只有你，你人也在命案現場，監視器也有拍到你進到被害者家中...'
            , 5, '你怎麼確定這不是你的幻想？'
            , 0, '');
          break;
        case 5:
          this.Ans(['師父真得存在，如果你遇過他，你就會相信了...', '有光明就有黑暗，黑暗的勢力一直都在，只是我們常常視而不見', 'NULL']
            , 6, '你說的師父存不存在，我不能確定...我們講求的是科學證據，兇器上的指紋只有你，你人也在命案現場，監視器也有拍到你進到被害者家中...'
            , 7, '我只是要跟你提醒，證據擺在眼前，如果你一直否認，沒有悔改之心。到時候送到法官那邊，法官會怎麼想？...我想對你是不利的。'
            , 0, '');
          break;
        case 6:
          this.Ans(['你難道不會懷疑，這世界上就是有科學沒有辦法解決的事情？', '生死有命，就看你相不相信罷了', 'NULL']
            , 8, '我也有信仰，我相信所有的宗教都是在勸人為善的，如果你因為生病而殺人。我勸你，不要撒謊，趕快承認...'
            , 7, '我只是要跟你提醒，證據擺在眼前，如果你一直否認，沒有悔改之心。到時候送到法官那邊，法官會怎麼想？...我想對你是不利的。'
            , 0, '');
          break;
        case 7:
          this.Ans(['我一直都是實話實說，但你從來不相信。你不相信我說的一切，反覆問我同樣的問題，你已經預設的結果，只是要我填空而已。', '法官會明理的，我相信法官知道發生了什麼事。只要法官不受到社會輿論的壓力。', 'NULL']
            , 8, '我也有信仰，我相信所有的宗教都是在勸人為善的，如果你因為生病而殺人。我勸你，不要撒謊，趕快承認...'
            , 9, '我會把你所說的都記錄下來，法官會有判斷。你就是一個人，必須要接受法律的約束。'
            , 0, '');
          break;
        case 8:
          this.Ans(['我沒有生病，我是天選之人，只是比較特別沒有生病。', '這發生的一切都是向善的，人各有命，我們不能強求。人類的律法是沒有辦法管束我的。', 'NULL']
            , 7, '我只是要跟你提醒，證據擺在眼前，如果你一直否認，沒有悔改之心。到時候送到法官那邊，法官會怎麼想？...我想對你是不利的。'
            , 9, '我會把你所說的都記錄下來，法官會有判斷。你就是一個人，必須要接受法律的約束。'
            , 0, '');
          break;
        case 9:
          // continue
          break;
        case 10:
          this.Ans(['之前不是說過了，就是朋友...本來有人來問事，我們就會當家人，宮裡大家都是一家人...', '他常常最後一個問事。我退駕後，他常常還在，就會一起去吃宵夜...大概就這樣。', 'NULL']
            , 12, '如果有必要我們會調閱你跟他的對話紀錄'
            , 11, '難道沒有多於朋友的關係嗎？'
            , 0, '');
          break;
        case 11:
          this.Ans(['什麼意思！飯可以亂吃，話不可以亂說，沒有證據不要在那邊...', '嚴格來說，他準備要開始修煉了，從最基本的開始。每個人資質不同，但都可以修，好好修，可以漸漸知道師父的意思...', 'NULL']
            , 12, '如果有必要我們會調閱你跟他的對話紀錄'
            , 14, '你要不要說說看借錢的事情？'
            , 0, '');
          break;
        case 12:
          this.Ans(['就去調啊！你們找不到任何證據的。', '就是比較常一起吃飯而已，還是你說借錢，之前借的錢都已經還了啊！', 'NULL']
            , 11, '難道沒有多於朋友的關係嗎？'
            , 13, '不只借錢，你姐說，你們甚至會一起單獨出遊個一兩天...'
            , 0, '');
          break;
        case 13:
          this.Ans(['陪他去散散心而已，有些問題自己要想通，師父怎麼幫忙也有限，也來辦事好一陣子了，一起出去走走還好吧！', '我姐這樣說？...那不然你說說，你現在在幻想哪套劇本？！', 'NULL']
            , 14, '你要不要說說看借錢的事情？'
            , 15, '當晚報案電話中，鄰居有聽到，起初比較是在性愛的聲音，不以為意，之後沒多久就聽到慘叫聲，他才報案'
            , 0, '');
          break;
        case 14:
          this.Ans(['那有什麼好說的，就已經還了啊！你們警察想像力真豐富，白的都可以說成黑的。', '之前廟裡比較缺，就問他有沒有辦法，他手頭也還可以，就借了。', 'NULL']
            , 13, '不只借錢，你姐說，你們甚至會一起單獨出遊個一兩天...'
            , 15, '當晚報案電話中，鄰居有聽到，起初比較是在性愛的聲音，不以為意，之後沒多久就聽到慘叫聲，他才報案'
            , 0, '');
          break;
        case 15:
          this.Ans(['你現在在鬼扯什麼！那裡街坊鄰居住這麼近，哪戶傳出來的還不知道呢！...師父跟我說你上個禮拜不知道跟誰去Hotel...', '那個打電話的，想像力真得很豐富...', 'NULL']
            , 16, '我不知道你在胡說八道什麼，現在是我在問你問題...你是不是跟死者在交往？'
            , 17, '你不知道殺人要坐牢嗎？你在這裡好好想想，殺人是不對的，就算是你是乩童也一樣。'
            , 0, '');
          break;
        case 16:
          this.Ans(['你要是再問一次相關的問題，師父說就不要回答了。', '我覺得我還有看到別的...恩，你帶去Hotel的對象好像不只一個...', 'NULL']
            , 17, '你不知道殺人要坐牢嗎？你在這裡好好想想，殺人是不對的，就算是你是乩童也一樣。'
            , 17, '你不知道殺人要坐牢嗎？你在這裡好好想想，殺人是不對的，就算是你是乩童也一樣。'
            , 0, '');
          break;
        case 17:
          // END
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          this.videoSrc = '/video/檢察官3-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/檢察官3-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 3:
          this.videoSrc = '/video/檢察官3-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官3-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 4:
          this.videoSrc = '/video/檢察官3-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 5:
          this.videoSrc = '/video/檢察官3-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官3-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 6:
          this.videoSrc = '/video/檢察官3-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 7:
          this.videoSrc = '/video/檢察官3-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 8:
          this.videoSrc = '/video/檢察官3-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官3-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 9:
          this.videoSrc = '/video/檢察官3-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 10:
          this.videoSrc = '/video/檢察官3-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官3-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 11:
          this.videoSrc = '/video/檢察官4-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官4-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 12:
          this.videoSrc = '/video/檢察官4-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 13:
          this.videoSrc = '/video/檢察官4-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官4-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 14:
          this.videoSrc = '/video/檢察官4-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 15:
          this.videoSrc = '/video/檢察官4-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官4-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 16:
          this.videoSrc = '/video/檢察官4-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 17:
          this.videoSrc = '/video/檢察官4-4.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官4-4.wav';
          this.playSound(this.soundSrc);
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}

.popImage
{
  position: fixed;
  top: 38vh;
  z-index: 99999;
  right: 28vw;
  img {
    width: 70%;
  }
}
</style>
