<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 檢察官
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}
    .popImage(v-show='bImageVisible')
      img(src="/img/刀子（圖）.png")

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      bImageVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['你剛剛還睡得著，你內心都不會不安嗎？'],
      bText: false,
      timer: null,
      mobileTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['我做了一個夢...', '我聽不懂你在說什麼！', ''],
        ['他死了嗎？', '我醒來的時候他已經死了...', ''],
        ['很像電腦螢幕壞了，但一直有人在跟我說話.', '不重要', ''],
        ['我醒來的時候他已經躺在那了', '七、七、四十九...', ''],
        ['叫我認罪', '叫我不要認罪', ''],
        ['他一直在我耳邊恐嚇我...', '我是被逼的...', ''],
        ['我當時有想要報警...', '我不知道發生了什麼事', ''],
        ['電話是我打的', '我太害怕了...怕被誤認為是兇手', ''],
        ['我不記得了...', '我沒有殺人', ''],
        ['', '', ''], // 10
        ['我忘了...不知道...', '我有問題要找他', ''],
        ['可能剛好沒拍到吧！', '我很早就走了，是早上過去看到他倒在那', ''],
        ['私事', '祂說不能說...', ''],
        ['私事', '我偶爾早上會去他家，幫他買早餐', ''],
        ['不方便說', '朋友', '他常來我宮裡'], // 15
        ['說了你也不信', '我能說的都已經說了', ''],
        ['普通', '他來的時候師父已經降駕，我沒有太多記憶', ''],
        ['我記得的都說了', '我們就是普通朋友...', ''],
        ['認得', '我不知道', ''],
        ['我昨晚去找他時，順便帶過去的', '我不記得了，可能是上次他跟我借的吧！', ''], // 20 刀子
        ['我找他喝酒...但在外面的居酒屋...', '我找他喝酒...宮裡還有一些水果我就帶過去了', ''],
        ['沒有，我一個人住', '我忘了幾點，總之我有回去', ''],
        ['我後來發現他家裡有刀子，就用他家的了', '對！我不確定他家裡有沒有刀子', ''],
        ['什麼意思，我不懂你在說什麼', '可能當時切了火龍果吧！', ''],
        ['我不知道，不要再問我了', '沒有，我沒有殺人', ''],
        ['', '', ''], // 26 電話響
        ['這是我的聲音', '聽起來不像我的聲音', ''],
        ['我沒有，我沒有殺他', '是祂殺的，是祂', ''],
        ['你說是就是吧！你們只會逼人承認...', '你們去做啊！你們查不出來的！', ''],
        ['我不能說，總之不是我', '一個邪惡的力量，入侵我的身體', ''], // 30
        ['我要聲請精神鑑定', '我沒有發瘋！！祂不存在在這世界上', ''],
        ['祂說不能相信你', '祂說我很正常', ''],
        ['對！他來問事，我下個禮拜要幫他祭改', '祂輸了，祂從來沒有輸過', ''],
        ['就說的不是我，說到底你就是不相信我', '我不知道...我真的不知道...', ''],
        ['我還來得及幫他祭改嗎？師父在問...', '就有一個聲音一直跟我說「他很壞」', ''],
        ['我沒有亂說，總之我去他家找他喝酒，然後我就睡著了，起來的時候，我就看到他倒在那...好像死了...', '我說了你們也不信，我為什麼要說', ''],
        ['', '', ''], // 37
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:4040', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });

    this.socket.on('endCall1', (data) => {
      console.log('endCall1', data);
      if (data === this.id){
        clearTimeout(this.mobileTimer);
        this.step = 27;
        this.textArray.push('那個聲音是誰？不是你嗎？');
        this.checkPlayVideo();
      }
    });

    this.startVisible = true;

    // 入場
    const src = '/video/檢察官1-1.mp4';
    this.playVideo(src);
    this.playSound('/sound/檢察官1-1.wav');

    // 背景BGM重複播放
    this.music = new Audio();
    this.music.pause();
    this.music.src = '/music/檢察官BGM.wav';
    this.music.loop = true;
    this.music.play();

    // 延遲出現對話框
    setTimeout(() => {
      this.dVisible = true;
      this.bText = true;
      this.step = 1;
    }, 2000);

    setInterval(() => {
      // console.log('Check!');
      this.$nextTick(() => {
        this.scrollDown();
      });
    }, 500);
  },
  destroyed() {
    this.socket.disconnect();
  },

  methods: {
    onTextComplete() {
      if (this.step !== 10){
        this.questionVisible = true;
      } else if (this.step === 10) {
        this.dVisible = false;
      }
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
        if (this.step !== 19) {
          this.bImageVisible = false;
        }
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 10) {
        setTimeout(() => {
          this.dVisible = true;
          this.step = 11;
          this.textArray.push('這是我們調閱巷口的監視器，昨天晚上10點38分，你到對方家中幹什麼？');
          this.checkPlayVideo();
        }, 21000);
      }

      if (this.step === 19) {
        this.bImageVisible = true;
      }

      if (this.step === 26) {
        setTimeout(() => {
          this.socket.emit('onCall1', this.id);
        }, 2000);
        this.mobileTimer = setTimeout(() => {
          this.step = 27;
          this.textArray.push('那個聲音是誰？不是你嗎？');
          this.checkPlayVideo();
        }, 90000);
      }

      if (this.step === 37){
        console.log('END :>> ');
        setTimeout(() => {
          this.music.pause();
          this.$router.push({ path: `/cp3`, query: { id: this.id, passcode: this.passcode }});
        }, 5000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
      // this.dVisible = true;
      // if (this.step === 10) { // CCTV 影像後
      //   this.step = 11;
      //   this.textArray.push('這是我們調閱巷口的監視器，昨天晚上10點38分，你到對方家中幹什麼？');
      //   this.checkPlayVideo();
      // }
      // if (this.step === 26) { // CCTV 影像後
      //   this.step = 27;
      //   this.textArray.push('那個聲音是誰？不是你嗎？');
      //   this.checkPlayVideo();
      // }
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          this.Ans(['我做了一個夢...', '我聽不懂你在說什麼！', 'NULL']
            , 3, '你做了什麼夢？'
            , 2, '朋友的屍體就躺在你身邊，你看起來卻相當平靜...。'
            , 0, '');
          break;
        case 2:
          this.Ans(['他死了嗎？', '我醒來的時候他已經死了...', 'NULL']
            , 4, '我們發現你在死者家中，死者身中七刀傷重不治，你不記得？'
            , 3, '你做了什麼夢？'
            , 0, '');
          break;
        case 3:
          this.Ans(['很像電腦螢幕壞了，但一直有人在跟我說話.', '不重要', 'NULL']
            , 5, '說些什麼話？'
            , 4, '我們發現你在死者家中，死者身中七刀傷重不治，你不記得？'
            , 0, '');
          break;
        case 4:
          this.Ans(['我醒來的時候他已經躺在那了', '七、七、四十九...', 'NULL']
            , 8, '那你為什麼不報警？'
            , 7, '回到現實吧！現實就是你朋友的屍體就躺在你旁邊'
            , 0, '');
          break;
        case 5:
          this.Ans(['叫我認罪', '叫我不要認罪', 'NULL']
            , 6, '所以你知道自己犯了法、做錯事？'
            , 7, '回到現實吧！現實就是你朋友的屍體就躺在你旁邊'
            , 0, '');
          break;
        case 6:
          this.Ans(['他一直在我耳邊恐嚇我...', '我是被逼的...', 'NULL']
            , 7, '回到現實吧！現實就是你朋友的屍體就躺在你旁邊'
            , 8, '那你為什麼不報警？'
            , 0, '');
          break;
        case 7:
          this.Ans(['我當時有想要報警...', '我不知道發生了什麼事', 'NULL']
            , 8, '那你為什麼不報警？'
            , 9, '難道人不是你殺的？'
            , 0, '');
          break;
        case 8:
          this.Ans(['電話是我打的', '我太害怕了...怕被誤認為是兇手', 'NULL']
            , 9, '難道人不是你殺的？'
            , 9, '難道人不是你殺的？'
            , 0, '');
          break;
        case 9:
          this.Ans(['我不記得了...', '我沒有殺人', 'NULL']
            , 7, '回到現實吧！現實就是你朋友的屍體就躺在你旁邊'
            , 10, '你看看這個...'
            , 0, '');
          break;
        case 10:
          // CCTV
          break;
        case 11:
          this.Ans(['我忘了...不知道...', '我有問題要找他', 'NULL']
            , 12, '之後的監視器也沒有看到你出來'
            , 13, '什麼問題？'
            , 0, '');
          break;
        case 12:
          this.Ans(['可能剛好沒拍到吧！', '我很早就走了，是早上過去看到他倒在那', 'NULL']
            , 9, '難道人不是你殺的？'
            , 14, '那你一大早為什麼要過去他家？'
            , 0, '');
          break;
        case 13:
          this.Ans(['私事', '祂說不能說...', 'NULL']
            , 15, '所以你們兩個的關係是？'
            , 16, '你現在是嫌疑人，我沒有要管你的私事，我們是要釐清案情，你確定都不說？'
            , 0, '');
          break;
        case 14:
          this.Ans(['私事', '我偶爾早上會去他家，幫他買早餐', 'NULL']
            , 16, '你現在是嫌疑人，我沒有要管你的私事，我們是要釐清案情，你確定都不說？'
            , 15, '所以你們兩個的關係是？'
            , 0, '');
          break;
        case 15:
          this.Ans(['不方便說', '朋友', '他常來我宮裡'] // 15
            , 16, '你現在是嫌疑人，我沒有要管你的私事，我們是要釐清案情，你確定都不說？'
            , 17, '所以你們很熟？...'
            , 17, '所以你們很熟？');
          break;
        case 16:
          this.Ans(['說了你也不信', '我能說的都已經說了', 'NULL']
            , 18, '你如果不誠實說，後面還有很多證據等著你。'
            , 10, '你看看這個...'
            , 0, '');
          break;
        case 17:
          this.Ans(['普通', '他來的時候師父已經降駕，我沒有太多記憶', 'NULL']
            , 18, '你如果不誠實說，後面還有很多證據等著你。'
            , 14, '那你一大早為什麼要過去他家？'
            , 0, '');
          break;
        case 18:
          this.Ans(['我記得的都說了', '我們就是普通朋友...', 'NULL']
            , 20, '這把刀子為什麼在那？'
            , 19, '你認得這把刀嗎？'
            , 0, '');
          break;
        case 19:
          this.Ans(['認得', '我不知道', 'NULL']
            , 20, '這把刀子為什麼在那？'
            , 18, '你如果不誠實說，後面還有很多證據等著你。'
            , 0, '');
          break;
        case 20:
          this.Ans(['我昨晚去找他時，順便帶過去的', '我不記得了，可能是上次他跟我借的吧！', 'NULL'] // 20 刀子
            , 21, '所以你確定昨晚有去找他？...你找他做什麼？'
            , 17, '所以你們很熟？'
            , 0, '');
          break;
        case 21:
          this.Ans(['我找他喝酒...但在外面的居酒屋...', '我找他喝酒...宮裡還有一些水果我就帶過去了', 'NULL']
            , 22, '有任何人可以證明你喝完酒後回家嗎？'
            , 23, '所以刀子是你一起帶過去的？'
            , 0, '');
          break;
        case 22:
          this.Ans(['沒有，我一個人住', '我忘了幾點，總之我有回去', 'NULL']
            , 20, '這把刀子為什麼在那？'
            , 24, '這把刀上並沒有被害者的指紋，這血跡你怎麼解釋'
            , 0, '');
          break;
        case 23:
          this.Ans(['我後來發現他家裡有刀子，就用他家的了', '對！我不確定他家裡有沒有刀子', 'NULL']
            , 24, '這把刀上並沒有被害者的指紋，這血跡你怎麼解釋'
            , 25, '兇刀上並沒有被害者指紋，也沒有發現其他的人指紋，就是你帶去的那把刀，你是否預謀殺害他？'
            , 0, '');
          break;
        case 24:
          this.Ans(['什麼意思，我不懂你在說什麼', '可能當時切了火龍果吧！', 'NULL']
            , 25, '兇刀上並沒有被害者指紋，也沒有發現其他的人指紋，就是你帶去的那把刀，你是否預謀殺害他？'
            , 26, '還不承認...你聽聽看這個(請注意您的手機)'
            , 0, '');
          break;
        case 25:
          this.Ans(['我不知道，不要再問我了', '沒有，我沒有殺人', 'NULL']
            , 26, '還不承認...你聽聽看這個(請注意您的手機)'
            , 24, '這把刀上並沒有被害者的指紋，這血跡你怎麼解釋'
            , 0, '');
          break;
        case 26:
          this.step = 27;
          break;
        case 27:
          this.Ans(['這是我的聲音', '聽起來不像我的聲音', 'NULL']
            , 28, '所以你承認你殺了他？'
            , 29, '不是你的聲音？那這是誰的聲音？你就承認吧！還要我們去做聲紋比對嗎？'
            , 0, '');
          break;
        case 28:
          this.Ans(['我沒有，我沒有殺他', '是祂殺的，是祂', 'NULL']
            , 29, '不是你的聲音？那這是誰的聲音？你就承認吧！還要我們去做聲紋比對嗎？'
            , 30, '「祂」是誰？'
            , 0, '');
          break;
        case 29:
          this.Ans(['你說是就是吧！你們只會逼人承認...', '你們去做啊！你們查不出來的！', 'NULL']
            , 26, '還不承認...你聽聽看這個(請注意您的手機)'
            , 31, '你到現在還在裝瘋賣傻！'
            , 0, '');
          break;
        case 30:
          this.Ans(['我不能說，總之不是我', '一個邪惡的力量，入侵我的身體', 'NULL'] // 30
            , 29, '不是你的聲音？那這是誰的聲音？你就承認吧！還要我們去做聲紋比對嗎？'
            , 31, '你到現在還在裝瘋賣傻！'
            , 0, '');
          break;
        case 31:
          this.Ans(['我要聲請精神鑑定', '我沒有發瘋！！祂不存在在這世界上', 'NULL']
            , 32, '我跟你說精神鑑定沒用的！你們這種人...一犯罪就想用精神鑑定來脫罪...我看過很多啦！就你不是，你很正常！'
            , 33, '你現在的職業是乩童對吧！神明沒有叫你要普渡眾生嗎？'
            , 0, '');
          break;
        case 32:
          this.Ans(['祂說不能相信你', '祂說我很正常', 'NULL']
            , 33, '你現在的職業是乩童對吧！神明沒有叫你要普渡眾生嗎？'
            , 33, '你現在的職業是乩童對吧！神明沒有叫你要普渡眾生嗎？'
            , 0, '');
          break;
        case 33:
          this.Ans(['對！他來問事，我下個禮拜要幫他祭改', '祂輸了，祂從來沒有輸過', 'NULL']
            , 35, '然後呢？然後發生什麼事？'
            , 34, '我希望你實話實說，如果你有任何精神狀況，法官會斟酌判刑，人是不是你殺的？'
            , 0, '');
          break;
        case 34:
          this.Ans(['就說的不是我，說到底你就是不相信我', '我不知道...我真的不知道...', 'NULL']
            , 36, '證據很清楚，如果你一直顛三倒四、胡言亂語，我只能把這些紀錄直接交給檢察官。'
            , 32, '我跟你說精神鑑定沒用的！你們這種人...一犯罪就想用精神鑑定來脫罪...我看過很多啦！就你不是，你很正常！'
            , 0, '');
          break;
        case 35:
          this.Ans(['我還來得及幫他祭改嗎？師父在問...', '就有一個聲音一直跟我說「他很壞」', 'NULL']
            , 37, '你犯下大錯了！你知道嗎！我會再過來，你先在這待著...'
            , 36, '證據很清楚，如果你一直顛三倒四、胡言亂語，我只能把這些紀錄直接交給檢察官。'
            , 0, '');
          break;
        case 36:
          this.Ans(['我沒有亂說，總之我去他家找他喝酒，然後我就睡著了，起來的時候，我就看到他倒在那...好像死了...', '我說了你們也不信，我為什麼要說', 'NULL']
            , 37, '你犯下大錯了！你知道嗎！我會再過來，你先在這待著...'
            , 33, '你現在的職業是乩童對吧！神明沒有叫你要普渡眾生嗎？'
            , 0, '');
          break;
        case 37:
          // END
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          this.videoSrc = '/video/檢察官1-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 3:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 4:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官1-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 5:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 6:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 7:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 8:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 9:
          this.videoSrc = '/video/檢察官1-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 10:
          this.videoSrc = '/video/巷口畫面CCTV.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/巷口畫面CCTV.wav'; // 缺
          this.playSound(this.soundSrc);
          break;
        case 11:
          this.videoSrc = '/video/檢察官2-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 12:
          this.videoSrc = '/video/檢察官2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 13:
          this.videoSrc = '/video/檢察官2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 14:
          this.videoSrc = '/video/檢察官2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 15:
          this.videoSrc = '/video/檢察官2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 16:
          this.videoSrc = '/video/檢察官2-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 17:
          this.videoSrc = '/video/檢察官2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 18:
          this.videoSrc = '/video/檢察官2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 19:
          this.videoSrc = '/video/檢察官2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 20:
          // 刀子圖片
          this.soundSrc = '/sound/檢察官2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 21:
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 22:
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 23:
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 24:
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 25:
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 26:
          // 電話響
          this.videoSrc = '/video/檢察官2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 27:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-4.wav';
          this.playSound(this.soundSrc);
          break;
        case 28:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 29:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 30:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 31:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-4.wav';
          this.playSound(this.soundSrc);
          break;
        case 32:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 33:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 34:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 35:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/檢察官2-4.wav';
          this.playSound(this.soundSrc);
          break;
        case 36:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 37:
          this.videoSrc = '/video/檢察官2-4.mp4';
          this.playVideo(this.videoSrc);
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}

.popImage
{
  position: fixed;
  top: 38vh;
  z-index: 99999;
  right: 28vw;
  img {
    width: 70%;
  }
}
</style>
