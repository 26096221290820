<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 辯護律師
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['準備要開庭了，你現在心情怎麼樣？'],
      bText: false,
      timer: null,
      nextTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['我會死嗎？', '心情很平靜', ''],
        ['要靠這個？我很正常啊！因為我能聽到上面的聲音所以說我有病嗎？你有病！你才有病！', '我想我準備好了，師父說大不了就去祂那邊而已', ''],
        ['我其實記不清楚那晚上的細節了，但所有人都說得好像真有這件事，你要我說沒有，我也不知道怎麼反駁', '我想我應該難逃一死...我不管說什麼，他們都不相信...', ''],
        ['', '', ''],
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    this.startVisible = true;

    // 入場
    const src = '/video/律師4-1.mp4';
    this.playVideo(src);
    this.playSound('/sound/律師4-1.wav');

    // 背景BGM重複播放
    this.music = new Audio();
    this.music.pause();
    this.music.src = '/music/律師BGM.wav';
    this.music.loop = true;
    this.music.play();

    // 延遲出現對話框
    setTimeout(() => {
      this.dVisible = true;
      this.bText = true;
      this.step = 1;
    }, 1000);

    setInterval(() => {
      // console.log('Check!');
      this.$nextTick(() => {
        this.scrollDown();
      });
    }, 500);
  },

  methods: {
    onTextComplete() {
      if (this.step !== 20){
        this.questionVisible = true;
      }
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 4){
        console.log('END :>> ');
        setTimeout(() => {
          this.music.pause();
          this.$router.push({ path: `/end`, query: { id: this.id, passcode: this.passcode }});
        }, 9000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          this.Ans(['我會死嗎？', '心情很平靜', 'NULL']
            , 2, '現在所有證據都對你很不利，主要要看精神鑑定的結果...刑法第19條有保障，如果犯案當下因為精神障礙或心智缺陷而不能控制自己或辨別當下的行為是違法的，會有機會被判無罪...但並不容易，你的說詞反覆不定，我並不是專業，我只能先等待鑑定結果...'
            , 3, '你有想過最壞的打算嗎？'
            , 0, '');
          break;
        case 2:
          this.Ans(['要靠這個？我很正常啊！因為我能聽到上面的聲音所以說我有病嗎？你有病！你才有病！', '我想我準備好了，師父說大不了就去祂那邊而已', 'NULL']
            , 4, '不管你怎麼想，我會盡可能幫你辯護，你好好想想，你還有什麼想說的...你把它寫出來，不管你要認罪或不認罪，好好把你心裡所想的說出來，好嗎？'
            , 4, '不管你怎麼想，我會盡可能幫你辯護，你好好想想，你還有什麼想說的...你把它寫出來，不管你要認罪或不認罪，好好把你心裡所想的說出來，好嗎？'
            , 0, '');
          break;
        case 3:
          this.Ans(['我其實記不清楚那晚上的細節了，但所有人都說得好像真有這件事，你要我說沒有，我也不知道怎麼反駁', '我想我應該難逃一死...我不管說什麼，他們都不相信...', 'NULL']
            , 4, '不管你怎麼想，我會盡可能幫你辯護，你好好想想，你還有什麼想說的...你把它寫出來，不管你要認罪或不認罪，好好把你心裡所想的說出來，好嗎？'
            , 2, '現在所有證據都對你很不利，主要要看精神鑑定的結果...刑法第19條有保障，如果犯案當下因為精神障礙或心智缺陷而不能控制自己或辨別當下的行為是違法的，會有機會被判無罪...但並不容易，你的說詞反覆不定，我並不是專業，我只能先等待鑑定結果...'
            , 0, '');
          break;
        case 4:
          // END
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          this.videoSrc = '/video/律師4-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/律師4-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師4-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 3:
          this.videoSrc = '/video/律師4-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師4-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 4:
          this.videoSrc = '/video/律師5-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師5-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 5:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}
</style>
