<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  // QRCode 進入畫面
  .qrcode
    h2 陳述清單：{{total}} 筆記錄
    el-table.dataTable(:data='tableData' stripe style='width: 100%' :default-sort='{ prop:"passcode", order:"descending" }')
      el-table-column(prop='passcode' label='場次驗證碼' sortable width='180')
      el-table-column(prop='qrcode' label='手機QRCODE' sortable width='180')
      el-table-column(prop='comments' label='最後陳述' sortable)
      el-table-column(prop='created' label='建立時間' sortable width='200')
  .code
    div(style='padding: 14px;')
      h3 場次對照
      div 1. 8vsRC9AM
      div 2. 7MVzkTXK
      div 3. qNtmhFfd
      div 4. DNk8mgPu
      div 5. 86bGpWHS
      div 6. mfa6nq8V
      div 7. 62w3S7gC
      div 8. 3W4fFAxH

</template>

<script>

export default {
  name: 'Entry',
  components: {
  },
  data() {
    return {
      loading: false,
      readyUser: 0,
      id: 0,
      tableData: [],
    };
  },
  computed: {
  },
  created() {
    this.id = this.$route.query.id;
    console.log('this.id :>> ', this.id);
  },
  mounted() {
    this.getComments();
  },

  methods: {
    getComments() {
      // fetch('https://localhost:4040/api/getComments')
      fetch('https://performing-insanity.winnowork.com:4040/api/getComments')
        .then(res => res.json())
        .then(myJson => {
          console.log('res', myJson);
          this.tableData = myJson.comments;
          this.total = myJson.total;
        });
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  min-height: 100vh;
  width: 100vw;
  background-attachment: fixed;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10vh;
    width: 70vw;
    h2 {
      color: white;
      text-align: right;
    }
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }
  .el-card {
    margin: 20px;
  }
  .code {
    width: 150px;
    position: fixed;
    left: 0;
    top: 30vh;
    background-color: aliceblue;
    text-align: left;
  }
}

</style>
