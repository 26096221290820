<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  // QRCode 進入畫面
  .qrcode
    el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        span 已按下確認的觀眾： {{ readyUser }}
        .bottom.clearfix
    el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        span 請按下開始啟動演出
        .bottom.clearfix
      el-button.button(type='text' @click='onStart') 開始
    el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      div(style='padding: 14px;')
        span 送出鈴聲
        .bottom.clearfix
      el-button.button(type='text' @click='onCall') 送出

</template>

<script>

export default {
  name: 'Entry',
  components: {
  },
  data() {
    return {
      readyUser: 0,
      id: 0,
    };
  },
  computed: {
  },
  created() {
    this.id = this.$route.query.id;
    console.log('this.id :>> ', this.id);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:3030', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });

    this.socket.on('onReady', () => {
      this.readyUser++;
    });
  },

  methods: {
    onStart() {
      // send obj example
      this.socket.emit('start');
      this.$alert('演出已開始', '通知', {
        confirmButtonText: '確定',
        callback: action => {
          // this.$message({
          // type: 'info',
          // message: `action: ${action}`,
          // });
        },
      });
    },
    onCall() {
      this.socket.emit('onCall1', this.id);
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }
  .el-card {
    margin: 20px;
  }
}

</style>
