<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 精神科醫師
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['我們收到你剛剛所填寫的自我檢測了，這些都是你真心的答案嗎？'],
      bText: false,
      timer: null,
      nextTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['', '', ''],
        ['是', '', ''],
        ['偶而不能', '我一直都跟師父相處得很好', ''],
        ['這是我的意思', '', ''],
        ['我會感覺到師父要來，師父也會跟我說要降駕了...祂進來後，我會很像坐在師父對面，看著自己。我像是他的魁儡，把身體借給他使用', '我會感覺到師父要來，師父也會說他要降駕了...然後我身體會有一陣熱熱的，很像被火包圍的感覺，之後我就會失去記憶...', ''],
        ['有時候有，有時候沒有，很不一定，大部分的時候沒有', '我沒有記憶，只有那個畫面，但他們討論什麼，我基本上都不知道，有些時候，退駕了，身邊的人會跟我說...', ''],
        ['祂叫我乖乖配合，我一直都乖乖配合，但常常覺得你們問題很奇怪。你們不了解到底發生了什麼事，也不可能了解...', '你說什麼時候？祂常常跟我說話啊！像那個警察一直逼我認罪，他根本就不懂，他不懂發生了什麼事... 師父就叫我不要太理他。', ''],
        ['很類似，但又不完全是，因為師父根本沒有出現', '我有一些畫面但很模糊...就是祂殺的', ''], // 8
        ['對麻！你比較知道事情狀況', '', ''],
        ['有些時候不見得是師父的意思', '就是啊！不然我難道會預知未來，那些信徒會一直來廟裡，就是因為師父的意思啊！他們覺得有幫助才來。', ''],
        ['對！就是祂的意思', '有時候很難說是誰的意思...', ''],
        ['因為之前都有講好，所以...我就把身體讓給祂了', '我有試過...但沒有辦法...', ''],
        ['師父輸了，師父管不動祂了...', '當然不是，而且師父會管祂，祂們的力量可以抗衡', ''],
        ['沒有，很真實...我知道什麼是假的什麼是真的...有些聲音聽起來跟真的一模一樣，但是是假的...', '沒有，師父跟另一個力量都不是我幻想出來的...', ''],
        ['我被逼去的', '是因為有一次不小心打傷人，師父也叫我去一下，說去一下比較好', ''],
        ['我快聽不到師父的聲音了，師父說，可以了，你有比較收斂，不用再去了...', '我覺得醫生只是在騙我，隨便講兩句話就開一堆藥給我，好像我越吃藥越是在承認說那天師父用酒杯敲那個信徒，是我的問題一樣...', ''],
        ['你們覺得是病，你們想要我吃藥，但我覺得不是，我只是不一樣，我能聽到師父的聲音，這沒有影響你們吧！', '我至始至終都不覺得自己有生病，我很正常', ''],
        ['我不知道，祂偶爾就是會出現，師父說這是孽緣，我得更努力修行才能擺脫', '不管什麼力量，都是來平衡人類，肉體只是一時的', ''],
        ['師父說，他不該這樣，是另一個力量在嫉妒', '他可能有做錯事吧！想必是很嚴重的事情', ''],
        ['我從來沒有這麼懷疑過，那些聲音都很真實，師父也曾經跟我介紹果祂，這世界有光亮就有黑暗，只是平常祂都不會出現', '這不是什麼內心的聲音，我很清楚好麻！你會分不清楚你內心的聲音嗎？', ''], // 20
        ['我說了，這不是我能決定的事情，他犯了什麼錯，只有上面知道...', '師父是跟我說，他好幾輩子都是孤煞星，這輩子也會作惡', ''],
        ['我們互動很好，他也很常來廟裡，這些不是都問過了嗎？', '要說衝突沒有，我們感情確實比一般信徒好，很常互動，他喝酒時也會跟我聊很多...', ''],
        ['我平常沒什麼壞念頭，就算有些小壞小惡跑出來，師父也會叫我別做', '你到底在意有所指些什麼？我聽不懂', ''],
        ['我知道，但我沒辦法，這是另一個世界的規定，我只是被迫執行。', '他會理解的，他去到另一個世界會知道自己做錯什麼...', ''],
        ['我很後悔，但這就是命，我本人對不起他，但是，人不是我殺的...', '一個朋友死了，我很難過，但你問我後不後悔...這事情輪不到我後悔...', ''],
        ['我知道在人世間殺人是不對的，但這不是我能決定的...', '或許吧！...我不知道...但事情已經發生了...', ''], // 26
        ['', '', ''],
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    this.startVisible = true;

    // 入場
    const src = '/video/高敏測試.mp4';
    this.playVideo(src);
    this.playSound('/sound/高敏測試.wav');

    // 延遲出現對話框
    setTimeout(() => {
      // 背景BGM重複播放
      this.music = new Audio();
      this.music.pause();
      this.music.src = '/music/精神科醫師BGM.wav';
      this.music.loop = true;
      this.music.play();

      this.dVisible = true;
      this.bText = true;
      this.step = 2;

      this.checkPlayVideo();

      setInterval(() => {
      // console.log('Check!');
        this.$nextTick(() => {
          this.scrollDown();
        });
      }, 500);
    }, 93000);
  },

  methods: {
    onTextComplete() {
      this.questionVisible = true;
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 27){
        console.log('END :>> ');
        setTimeout(() => {
          this.music.pause();
          this.$router.push({ path: `/cp6`, query: { id: this.id, passcode: this.passcode }});
        }, 1000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          // VIDEO
          break;
        case 2:
          this.Ans(['是', 'NULL', 'NULL']
            , 3, '你的檢測結果，恩...你是一個比較敏感的人，延續幾年前在醫院就診的資料來看...你這幾年，一直都能好好分辨那些聲音嗎？'
            , 0, ''
            , 0, '');
          break;
        case 3:
          this.Ans(['偶而不能', '我一直都跟師父相處得很好', 'NULL']
            , 4, '這確實是你的意思回答的吧！還是師父要求你這麼填的呢？'
            , 5, '...當乩童很需要天賦，你很厲害，你可以描述一下神明進到你身體的時候是什麼感覺嗎？'
            , 0, '');
          break;
        case 4:
          this.Ans(['這是我的意思', 'NULL', 'NULL']
            , 5, '...當乩童很需要天賦，你很厲害，你可以描述一下神明進到你身體的時候是什麼感覺嗎？'
            , 0, ''
            , 0, '');
          break;
        case 5:
          this.Ans(['我會感覺到師父要來，師父也會跟我說要降駕了...祂進來後，我會很像坐在師父對面，看著自己。我像是他的魁儡，把身體借給他使用', '我會感覺到師父要來，師父也會說他要降駕了...然後我身體會有一陣熱熱的，很像被火包圍的感覺，之後我就會失去記憶...', 'NULL']
            , 6, '你的意思是你當下是有記憶的？'
            , 7, '事實上這兩天我們都在觀察你，有聲音在要求你說出某些話嗎？祂在跟你說什麼？'
            , 0, '');
          break;
        case 6:
          this.Ans(['有時候有，有時候沒有，很不一定，大部分的時候沒有', '我沒有記憶，只有那個畫面，但他們討論什麼，我基本上都不知道，有些時候，退駕了，身邊的人會跟我說...', 'NULL']
            , 7, '事實上這兩天我們都在觀察你，有聲音在要求你說出某些話嗎？祂在跟你說什麼？'
            , 8, '所以那天你在死者家中，也有類似的狀況？'
            , 0, '');
          break;
        case 7:
          this.Ans(['祂叫我乖乖配合，我一直都乖乖配合，但常常覺得你們問題很奇怪。你們不了解到底發生了什麼事，也不可能了解...', '你說什麼時候？祂常常跟我說話啊！像那個警察一直逼我認罪，他根本就不懂，他不懂發生了什麼事... 師父就叫我不要太理他。', 'NULL']
            , 8, '所以那天你在死者家中，也有類似的狀況？'
            , 9, '一般人確實很難理解，會容易覺得你在騙人...'
            , 0, '');
          break;
        case 8:
          this.Ans(['很類似，但又不完全是，因為師父根本沒有出現', '我有一些畫面但很模糊...就是祂殺的', 'NULL'] // 8
            , 10, '為什麼你覺得那些聲音是師父的意思？'
            , 9, '一般人確實很難理解，會容易覺得你在騙人...'
            , 8, '▲此時突然一陣奇異的聲響竄入');
          break;
        case 9:
          this.Ans(['對麻！你比較知道事情狀況', 'NULL', 'NULL']
            , 10, '為什麼你覺得那些聲音是師父的意思？'
            , 0, ''
            , 0, '');
          break;
        case 10:
          this.Ans(['有些時候不見得是師父的意思', '就是啊！不然我難道會預知未來，那些信徒會一直來廟裡，就是因為師父的意思啊！他們覺得有幫助才來。', 'NULL']
            , 11, '那是誰的意思？你之前提到的「另一個力量」？'
            , 12, '這問題對我們來說很重要...當祂們出現時，你可以決定事情嗎？'
            , 0, '');
          break;
        case 11:
          this.Ans(['對！就是祂的意思', '有時候很難說是誰的意思...', 'NULL']
            , 13, '祂要你做什麼事都一定得做嗎？'
            , 12, '這問題對我們來說很重要...當祂們出現時，你可以決定事情嗎？'
            , 0, '');
          break;
        case 12:
          this.Ans(['因為之前都有講好，所以...我就把身體讓給祂了', '我有試過...但沒有辦法...', 'NULL']
            , 13, '祂要你做什麼事都一定得做嗎？'
            , 14, '你有沒有懷疑過神明是你的幻想？'
            , 0, '');
          break;
        case 13:
          this.Ans(['師父輸了，師父管不動祂了...', '當然不是，而且師父會管祂，祂們的力量可以抗衡', 'NULL']
            , 15, '那之前你會什麼會去看精神科？'
            , 14, '你有沒有懷疑過神明是你的幻想？'
            , 0, '');
          break;
        case 14:
          this.Ans(['沒有，很真實...我知道什麼是假的什麼是真的...有些聲音聽起來跟真的一模一樣，但是是假的...', '沒有，師父跟另一個力量都不是我幻想出來的...', 'NULL']
            , 15, '那之前你會什麼會去看精神科？'
            , 18, '好端端的為什麼會有另一個力量，師父照理來說是不會害人的'
            , 0, '');
          break;
        case 15:
          this.Ans(['我被逼去的', '是因為有一次不小心打傷人，師父也叫我去一下，說去一下比較好', 'NULL']
            , 17, '所以你自己其實不想去，也不覺得自己可能生病了？'
            , 16, '那為什麼後來又不去了？'
            , 0, '');
          break;
        case 16:
          this.Ans(['我快聽不到師父的聲音了，師父說，可以了，你有比較收斂，不用再去了...', '我覺得醫生只是在騙我，隨便講兩句話就開一堆藥給我，好像我越吃藥越是在承認說那天師父用酒杯敲那個信徒，是我的問題一樣...', 'NULL']
            , 18, '好端端的為什麼會有另一個力量，師父照理來說是不會害人的'
            , 17, '所以你自己其實不想去，也不覺得自己可能生病了？'
            , 0, '');
          break;
        case 17:
          this.Ans(['你們覺得是病，你們想要我吃藥，但我覺得不是，我只是不一樣，我能聽到師父的聲音，這沒有影響你們吧！', '我至始至終都不覺得自己有生病，我很正常', 'NULL']
            , 18, '好端端的為什麼會有另一個力量，師父照理來說是不會害人的'
            , 16, '那為什麼後來又不去了？'
            , 0, '');
          break;
        case 18:
          this.Ans(['我不知道，祂偶爾就是會出現，師父說這是孽緣，我得更努力修行才能擺脫', '不管什麼力量，都是來平衡人類，肉體只是一時的', 'NULL']
            , 19, '你殺了他是不是因為覺得他需要被懲罰？'
            , 20, '你覺得那另一個力量跟師父一樣確實存在嗎？還是是你內心的聲音？並不是來自另一個世界...'
            , 0, '');
          break;
        case 19:
          this.Ans(['師父說，他不該這樣，是另一個力量在嫉妒', '他可能有做錯事吧！想必是很嚴重的事情', 'NULL']
            , 20, '你覺得那另一個力量跟師父一樣確實存在嗎？還是是你內心的聲音？並不是來自另一個世界...'
            , 21, '是什麼錯誤的事情...就非得用殺了他來懲罰嗎？'
            , 0, '');
          break;
        case 20:
          this.Ans(['我從來沒有這麼懷疑過，那些聲音都很真實，師父也曾經跟我介紹果祂，這世界有光亮就有黑暗，只是平常祂都不會出現', '這不是什麼內心的聲音，我很清楚好麻！你會分不清楚你內心的聲音嗎？', 'NULL'] // 20
            , 22, '會不會是你個人跟死者的關係，才導致這樣的悲劇發生? 你們之間沒有什麼衝突嗎？'
            , 23, '有些時候內心裡會有些不好的念頭，但是是可以被控制的，因為知道那是不對的...'
            , 0, '');
          break;
        case 21:
          this.Ans(['我說了，這不是我能決定的事情，他犯了什麼錯，只有上面知道...', '師父是跟我說，他好幾輩子都是孤煞星，這輩子也會作惡', 'NULL']
            , 24, '你知不知道殺了他是不對的？'
            , 22, '會不會是你個人跟死者的關係，才導致這樣的悲劇發生? 你們之間沒有什麼衝突嗎？'
            , 0, '');
          break;
        case 22:
          this.Ans(['我們互動很好，他也很常來廟裡，這些不是都問過了嗎？', '要說衝突沒有，我們感情確實比一般信徒好，很常互動，他喝酒時也會跟我聊很多...', 'NULL']
            , 23, '有些時候內心裡會有些不好的念頭，但是是可以被控制的，因為知道那是不對的...'
            , 23, '有些時候內心裡會有些不好的念頭，但是是可以被控制的，因為知道那是不對的...'
            , 0, '');
          break;
        case 23:
          this.Ans(['我平常沒什麼壞念頭，就算有些小壞小惡跑出來，師父也會叫我別做', '你到底在意有所指些什麼？我聽不懂', 'NULL']
            , 24, '你知不知道殺了他是不對的？'
            , 22, '會不會是你個人跟死者的關係，才導致這樣的悲劇發生? 你們之間沒有什麼衝突嗎？'
            , 0, '');
          break;
        case 24:
          this.Ans(['我知道，但我沒辦法，這是另一個世界的規定，我只是被迫執行。', '他會理解的，他去到另一個世界會知道自己做錯什麼...', 'NULL']
            , 25, '你後悔嗎？'
            , 26, '你覺得你該接受懲罰嗎？'
            , 0, '');
          break;
        case 25:
          this.Ans(['我很後悔，但這就是命，我本人對不起他，但是，人不是我殺的...', '一個朋友死了，我很難過，但你問我後不後悔...這事情輪不到我後悔...', 'NULL']
            , 26, '你覺得你該接受懲罰嗎？'
            , 26, '你覺得你該接受懲罰嗎？'
            , 0, '');
          break;
        case 26:
          this.Ans(['我知道在人世間殺人是不對的，但這不是我能決定的...', '或許吧！...我不知道...但事情已經發生了...', 'NULL']
            , 27, ' '
            , 27, ' '
            , 0, '');
          break;
        case 27:
          // END
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          // this.videoSrc = '/video/精神科醫師3-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/精神科醫師3-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師3-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 3:
          this.videoSrc = '/video/精神科醫師3-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 4:
          this.videoSrc = '/video/精神科醫師3-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師3-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 5:
          this.videoSrc = '/video/精神科醫師3-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 6:
          this.videoSrc = '/video/精神科醫師3-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師3-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 7:
          this.videoSrc = '/video/精神科醫師3-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 8:
          this.videoSrc = '/video/精神科醫師4-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師4-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 9:
          this.videoSrc = '/video/精神科醫師4-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 10:
          this.videoSrc = '/video/精神科醫師4-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師4-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 11:
          this.videoSrc = '/video/精神科醫師4-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 12:
          this.videoSrc = '/video/精神科醫師4-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師4-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 13:
          this.videoSrc = '/video/精神科醫師4-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 14:
          this.videoSrc = '/video/精神科醫師5-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師5-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 15:
          this.videoSrc = '/video/精神科醫師5-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 16:
          this.videoSrc = '/video/精神科醫師5-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師5-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 17:
          this.videoSrc = '/video/精神科醫師5-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 18:
          this.videoSrc = '/video/精神科醫師5-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 19:
          this.videoSrc = '/video/精神科醫師5-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 20:
          this.videoSrc = '/video/精神科醫師6-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師6-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 21:
          this.videoSrc = '/video/精神科醫師5-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 22:
          this.videoSrc = '/video/精神科醫師6-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師6-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 23:
          this.videoSrc = '/video/精神科醫師6-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 24:
          this.videoSrc = '/video/精神科醫師6-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師6-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 25:
          this.videoSrc = '/video/精神科醫師6-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 26:
          this.videoSrc = '/video/精神科醫師6-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師6-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 27:
          this.videoSrc = '/video/精神科醫師6-3.mp4';
          this.playVideo(this.videoSrc);
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}
</style>
