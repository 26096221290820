<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  .video-player-box(v-show='startVisible')
    video-player( ref="videoPlayer"
                  class="vjs-custom-skin"
                  :options="playerOptions"
                  @play="onPlayerPlay($event)"
                  @ready="onPlayerReady($event)"
                  @pause='onPause($event)'
                  @ended='onEnded($event)'
                )
  .endText(v-show='bVisible')
    vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='200'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='solid'
                    )
  el-dialog.info-dialog(title='好好把你心裡所想的說出來，好嗎？' :show-close='false' :close-on-click-modal='false' :close-on-press-escape='false' :visible.sync="dVisible")
    .info
      .ok
        el-input(
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 20}"
          placeholder="請輸入內容"
          v-model="textarea")
        el-button.submit(type="info" @click='onFinish') 送出
  transition(name='fade')
    div.hit(v-show='bHit') 體驗結束 關閉視窗即可離開
</template>

<script>
import { setTimeout } from 'timers';
import { VueTyper } from 'vue-typer';

export default {
  name: 'End',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      loading: false,
      bVisible: false,
      dVisible: true,
      bText: false,
      bHit: false,
      text: ['請深深牢記你最後說的話'],
      textarea: '',
      music: null,
      sound: null,
      videoIndex: 1,
      startVisible: false,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: false,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      oComments: {
        passcode: '',
        qrcode: '',
        comments: '',
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:4040', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });
  },

  methods: {
    onFinish() {
      this.oComments.qrcode = this.id;
      this.oComments.passcode = this.passcode;
      this.oComments.comments = this.textarea;
      this.oComments.created = this.Date();
      this.socket.emit('onComments', this.oComments);
      this.dVisible = false;
      setTimeout(() => {
        this.bVisible = true;
        this.bText = true;
      }, 1000);

      setTimeout(() => {
        this.startVisible = true;
        const src = '/video/升天.mp4';
        this.playVideo(src);
        this.music = new Audio();
        this.music.pause();
        this.music.src = '/music/升天v2.wav';
        this.music.loop = false;
        this.music.play();
      }, 8000);
    },
    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
      this.startVisible = false;
      this.text = '最後，想提醒您，請你帶一顆紅肉火龍果來，這是你生前最愛吃的食物。';
      setTimeout(() => {
        this.text = '感謝您參與《操演瘋狂》互動遊戲，請深深牢記您的最後陳述...';
        setTimeout(() => {
          this.bHit = true;
        }, 10000);
      }, 14000);
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };

      const newmonth = date.month > 10 ? date.month : '0' + date.month;
      const newday = date.date > 10 ? date.date : '0' + date.date;
      const newminutes = date.minutes > 10 ? date.minutes : '0' + date.minutes;
      const newseconds = date.seconds > 10 ? date.seconds : '0' + date.seconds;
      //   const newminutes = date.minutes < 10 ? "0" + date.minutes : date.minutes;
      //   const newseconds = date.seconds < 10 ? "0" + date.seconds : date.seconds;

      return date.year + '-' + newmonth + '-' + newday + ' ' + date.hours + ':' + newminutes + ':' + newseconds;
      // this.dateTime =
      //   date.year +
      //   '-' +
      //   newmonth +
      //   '-' +
      //   newday +
      //   ' ' +
      //   date.hours +
      //   ':' +
      //   newminutes +
      //   ':' +
      //   newseconds;
    },
  },
};
</script>

<style lang='scss' scoped>
.container {
  background-color: black;
  height: 100vh;
  width: 100vw;

  .video-player-box {
    width: 100%;
  }
  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }
  .endText {
    padding-top: 40vh;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;

    ::v-deep .vue-typer .left .typed{
      color: white;
      font-size: 2em;
    }
  }
  .ok {
    margin-top: 36px;
  }
  .submit{
    margin-top: 20px;
  }
  .hit{
    position: fixed;
    top:90vh;
    color:gray;
    width: 100%;
    text-align: center;
  }
  .dialogue {
    width: 60vw;
    height: 60vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: auto;
    left: auto;
    top: 15vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
    .text-line {
      border-bottom: inset;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
