<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 辯護律師
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['您好，我是法扶派來的辯護律師，你好...你現在心情還好嗎？'],
      bText: false,
      timer: null,
      nextTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['（不想回答）', '還可以', ''],
        ['好', '都可以', ''],
        ['不要問我，我不知道', '好，我試試看', ''],
        ['我記得的不多', '我沒有殺人', ''],
        ['我不知道，我記不清楚了...', '跟你說的一樣，但我沒有殺人！', ''],
        ['我去他家找他，然後...一起喝酒，之後的事情我都忘了...', '他常來宮裡，大概有兩、三年了...', ''],
        ['請你也相信我，我沒有殺人！', '你到底是誰？...你們是不是串通好的？', ''],
        ['我平常就會去找他喝酒', '我忘了，應該是他有事找我吧！', ''],
        ['不能說', '什麼都聊，隨便聊...', ''],
        ['有，他私人的事情', '沒有，我信徒那麼多誰記得住！', '我不知道......'],
        ['你是誰派來偷聽我們的祕密的？', '說出來會被處罰...', ''],
        ['不能隨便透露信徒的問題', '因為有人在「看著」我，他無所不在...', ''],
        ['我沒有太多印象，應該有吧！', '你不要再問了，他會生氣！', ''],
        ['師父', '就跟你說不要再說了！', ''],
        ['是...但我真得沒有殺人！', '...算認識，但不算熟，偶而喝酒還好吧！', ''],
        ['聊很多！什麼都聊，基本上祂會一直跟我說話', '師父說可以相信你', ''],
        ['我不知道要說幾次，我真得不記得了', '好，我仔細想想', '是師父...不是，不是師父'],
        ['師父說不要做精神鑑定，我沒有發瘋', '我沒有殺人，請相信我', ''],
        ['我忘了，但我覺得我沒有', '沒有！絕對沒有！', ''], // index 19 - 1
        ['', '', ''],
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    this.startVisible = true;

    // 入場
    const src = '/video/律師1-1.mp4';
    this.playVideo(src);
    this.playSound('/sound/律師1-1.wav');

    // 背景BGM重複播放
    this.music = new Audio();
    this.music.pause();
    this.music.src = '/music/律師BGM.wav';
    this.music.loop = true;
    this.music.play();

    // 延遲出現對話框
    setTimeout(() => {
      this.dVisible = true;
      this.bText = true;
      this.step = 1;
    }, 7000);

    setInterval(() => {
      // console.log('Check!');
      this.$nextTick(() => {
        this.scrollDown();
      });
    }, 500);
  },

  methods: {
    onTextComplete() {
      if (this.step !== 20){
        this.questionVisible = true;
      }
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 20){
        console.log('END :>> ');
        setTimeout(() => {
          this.dVisible = false;
          // this.textArray.push('你繼續在這邊待著，我時間差不多了，你想到什麼再跟我說...好嗎？我還會再過來...');
          setTimeout(() => {
            this.step = 21;
            console.log('Ans / end step :>> ', this.step);
            this.dVisible = false;
            this.checkPlayVideo();
            setTimeout(() => {
              this.music.pause();
              this.$router.push({ path: `/cp2`, query: { id: this.id, passcode: this.passcode }});
            }, 40000);
          }, 3000);
        }, 5000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          this.Ans(['（不想回答）', '還可以', 'NULL']
            , 2, '我希望你可以跟我合作，我是來幫你的。'
            , 3, '我得詢問你一些問題，你記得就記得，如果不確定就跟我說不確定，好嗎？'
            , 0, '');
          break;
        case 2:
          this.Ans(['好', '都可以', 'NULL']
            , 3, '我得詢問你一些問題，你記得就記得，如果不確定就跟我說不確定，好嗎？'
            , 4, '你殺了人，大家想要知道發生了什麼事...'
            , 0, '');
          break;
        case 3:
          this.Ans(['不要問我，我不知道', '好，我試試看', 'NULL']
            , 4, '你殺了人，大家想要知道發生了什麼事...'
            , 5, '我剛剛先了解一下狀況，你說你不知道發生了什麼事，發現他死的時候，你人在現場...'
            , 0, '');
          break;
        case 4:
          this.Ans(['我記得的不多', '我沒有殺人', 'NULL']
            , 5, '我剛剛先了解一下狀況，你說你不知道發生了什麼事，發現他死的時候，你人在現場...'
            , 5, '我剛剛先了解一下狀況，你說你不知道發生了什麼事，發現他死的時候，你人在現場...'
            , 0, '');
          break;
        case 5:
          this.Ans(['我不知道，我記不清楚了...', '跟你說的一樣，但我沒有殺人！', 'NULL']
            , 6, '那你還記得些什麼？'
            , 7, '請你相信我...這樣我們才有辦法協助你...'
            , 0, '');
          break;
        case 6:
          this.Ans(['我去他家找他，然後...一起喝酒，之後的事情我都忘了...', '他常來宮裡，大概有兩、三年了...', 'NULL']
            , 7, '你怎麼會去他家，你還記得嗎？'
            , 7, '你怎麼會去他家，你還記得嗎？'
            , 0, '');
          break;
        case 7:
          this.Ans(['請你也相信我，我沒有殺人！', '你到底是誰？...你們是不是串通好的？', 'NULL']
            , 8, '你怎麼會去他家，你還記得嗎？'
            , 1, '您好，我是法扶派來的辯護律師，你好...你現在心情還好嗎？'
            , 0, '');
          break;
        case 8:
          this.Ans(['我平常就會去找他喝酒', '我忘了，應該是他有事找我吧！', 'NULL']
            , 9, '喝酒時都聊什麼？'
            , 10, '據你工作的廟裡的說法，他前一天晚上剛好有去問事，你有印象嗎？'
            , 0, '');
          break;
        case 9:
          this.Ans(['不能說', '什麼都聊，隨便聊...', 'NULL']
            , 11, '你們都聊些什麼？'
            , 10, '據你工作的廟裡的說法，他前一天晚上剛好有去問事，你有印象嗎？'
            , 0, '');
          break;
        case 10:
          this.Ans(['有，他私人的事情', '沒有，我信徒那麼多誰記得住！', '我不知道......']
            , 11, '你們都聊些什麼？'
            , 13, '聽廟裡的說法，他到廟裡問事有一陣子，是嗎？'
            , 6, '那你還記得些什麼？');
          break;
        case 11:
          this.Ans(['你是誰派來偷聽我們的祕密的？', '說出來會被處罰...', 'NULL']
            , 7, '請你相信我...這樣我們才有辦法協助你...'
            , 12, '為什麼會被處罰？'
            , 0, '');
          break;
        case 12:
          this.Ans(['不能隨便透露信徒的問題', '因為有人在「看著」我，他無所不在...', 'NULL']
            , 13, '聽廟裡的說法，他到廟裡問事有一陣子，是嗎？'
            , 14, '誰？'
            , 0, '');
          break;
        case 13:
          this.Ans(['我沒有太多印象，應該有吧！', '你不要再問了，他會生氣！', 'NULL']
            , 15, '很明顯跟廟裡的人說得有落差，他們都說你們關係很好，也常常互動、約喝酒。'
            , 14, '誰？'
            , 0, '');
          break;
        case 14:
          this.Ans(['師父', '就跟你說不要再說了！', 'NULL']
            , 16, '你說你服務的神嗎？...師父都跟你說什麼？'
            , 10, '據你工作的廟裡的說法，他前一天晚上剛好有去問事，你有印象嗎？'
            , 0, '');
          break;
        case 15:
          this.Ans(['是...但我真得沒有殺人！', '...算認識，但不算熟，偶而喝酒還好吧！', 'NULL']
            , 17, '你被發現在命案現場，對方還是你認識的人，請你仔細回想發生了什麼事...'
            , 17, '你被發現在命案現場，對方還是你認識的人，請你仔細回想發生了什麼事...'
            , 0, '');
          break;
        case 16:
          this.Ans(['聊很多！什麼都聊，基本上祂會一直跟我說話', '師父說可以相信你', 'NULL']
            , 18, '我會幫你聲請精神鑑定，好嗎？'
            , 18, '我會幫你聲請精神鑑定，好嗎？'
            , 0, '');
          break;
        case 17:
          this.Ans(['我不知道要說幾次，我真得不記得了', '好，我仔細想想', '是師父...不是，不是師父']
            , 13, '聽廟裡的說法，他到廟裡問事有一陣子，是嗎？'
            , 18, '我會幫你聲請精神鑑定，好嗎？'
            , 16, '你說你服務的神嗎？...師父都跟你說什麼？');
          break;
        case 18:
          this.Ans(['師父說不要做精神鑑定，我沒有發瘋', '我沒有殺人，請相信我', 'NULL']
            , 19, '你真的沒有殺了他嗎？'
            , 20, '你繼續在這邊待著，我時間差不多了，你想到什麼再跟我說...好嗎？我還會再過來...'
            , 0, '');
          break;
        case 19:
          this.Ans(['我忘了，但我覺得我沒有', '沒有！絕對沒有！', 'NULL']
            , 20, '你繼續在這邊待著，我時間差不多了，你想到什麼再跟我說...好嗎？我還會再過來...'
            , 17, '你被發現在命案現場，對方還是你認識的人，請你仔細回想發生了什麼事...'
            , 0, '');
          break;
        case 20:
          break;
        case 21:
          // VIDEO
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          this.videoSrc = '/video/律師1-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/律師2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 3:
          this.videoSrc = '/video/律師2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 4:
          this.videoSrc = '/video/律師2-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師2-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 5:
          this.videoSrc = '/video/律師2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 6:
          this.videoSrc = '/video/律師2-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 7:
          this.videoSrc = '/video/律師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 8:
          this.videoSrc = '/video/律師2-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 9:
          this.videoSrc = '/video/律師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 10:
          this.videoSrc = '/video/律師2-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 11:
          this.videoSrc = '/video/律師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 12:
          this.videoSrc = '/video/律師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 13:
          this.videoSrc = '/video/律師2-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 14:
          this.videoSrc = '/video/律師3-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師3-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 15:
          this.videoSrc = '/video/律師3-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 16:
          this.videoSrc = '/video/律師3-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 17:
          this.videoSrc = '/video/律師3-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 18:
          this.videoSrc = '/video/律師3-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/律師3-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 19:
          this.videoSrc = '/video/律師3-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 20:
          this.videoSrc = '/video/律師3-3.mp4';
          this.playVideo(this.videoSrc);
          // this.soundSrc = '/sound/律師3-3.wav';
          // this.playSound(this.soundSrc);
          break;
        case 21:
          this.videoSrc = '/video/夢境影像.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/電腦閃頻夢境.wav';
          this.playSound(this.soundSrc);
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}
</style>
