<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
  // QRCode 進入畫面
  .qrcode(v-show='qrcodeVisible')
    el-card(shadow='hover')
      //- img.image(src='/img/qrcode.png' width='250')
      qrcode-vue(:value='"https://performing-insanity.winnowork.com/#/mobile?id=" + this.id' size="200" level="H")
      div(style='padding: 14px;')
        span 請使用手機掃描並維持畫面開啟
        .bottom.clearfix
      el-button.button(type='text' @click='onStart') 已掃描並進入
      //- el-button.button(type='text' @click='onTest') 測試

  .video-player-box(v-show='startVisible')
    video-player( ref="videoPlayer"
                  class="vjs-custom-skin"
                  :options="playerOptions"
                  @play="onPlayerPlay($event)"
                  @ready="onPlayerReady($event)"
                  @ended='onEnded($event)'
                )
  transition(name='fade')
    .black(v-show='bFade')

  el-dialog.info-dialog(title='說明事項' :show-close='false' :close-on-click-modal='false' :close-on-press-escape='false' :visible.sync="infoVisible")
    .info
      h4 親愛的《操演瘋狂》觀眾您好，
      h4 正式進入線上互動體驗前，請您遵循以下規範以及相關注意事項：
      h4 1、請以電腦開啟此頁面以順利執行線上體驗，並建議您使用Chrome瀏覽器之「無痕模式」以獲得最佳體驗。
      h4 2、本互動需同時使用您的手機及電腦，請將您的手機保持開啟狀態，並置放於電腦旁、開啟鈴響功能。
      h4 3、建議您戴著耳機進行本次體驗，以獲得最佳效果。
      h4 4、参與過程中，請務必依循網頁系統的指示完成動作，切勿自行離開系統以及網頁。
      h4 5、使用全螢幕模式可享有更佳的體驗。
      h2
      h4 如果在參與過程中，您有任何疑問或是操作上的問題聯繫：cocoism.service@gmail.com
      .ok
        h3 如果以上規範皆以完整了解，請輸入「驗證碼」並按下「確認」，我們即將開始.....
        .passcode
          el-input.input(v-model="Passcode" placeholder='請輸入「驗證碼」')
          el-button(type="info" @click='onReady') 確認
          div.hit(v-show='bHit') 您輸入的驗證碼有誤，請重新確認或聯繫我們。
        //- h3 系統尚未開放，將於 5/24 開放。

</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'Entry',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      id: 0,
      startCheck: true, // debug
      infoVisible: true,
      loading: false,
      text: '「請等待片刻」',
      bClickForgot: false,
      qrcodeVisible: true,
      startVisible: false,
      questionVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: false,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      bFade: false,
      bHit: false,
      Passcode: '',
      arrayPasscode: [
        'UaTbbqKf',
        '8vsRC9AM',
        '7MVzkTXK',
        'qNtmhFfd',
        'DNk8mgPu',
        '86bGpWHS',
        'mfa6nq8V',
        '62w3S7gC',
        '3W4fFAxH',
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = Date.now();
    // this.id = this.$route.query.id;
    console.log('this.id :>> ', this.id);
  },
  mounted() {
    // eslint-disable-next-line no-undef
    // this.socket = io('https://localhost:4040', {
    // eslint-disable-next-line no-undef
    this.socket = io('https://performing-insanity.winnowork.com:4040', {
      // withCredentials: true,
      extraHeaders: {
        'performing-insanity-header': 'abcd',
      },
      query: {
        'client': 'mobile',
      },
    });

    this.socket.on('connect', () => {
      console.log('socket.io is connected');
    });

    this.socket.on('onReady', (data) => {
      console.log('onReady', data);
      this.bHit = false;
      this.infoVisible = false;
    });

    this.socket.on('start', () => {
      console.log('Got START.');
      this.startCheck = true;
    });

    setTimeout(() => {
      this.loading = false;
    }, 2000);
    this.show();
    this.music = new Audio();
    this.music.pause();
  },
  destroyed() {
    this.socket.disconnect();
  },

  methods: {
    onReady() {
      // let oCheck = {
      //   id: this.id,
      //   passcode: this.Passcode,
      // };
      // this.socket.emit('onPasscode', oCheck);
      let checkPasscode = false;
      this.arrayPasscode.forEach((keyword) => {
        if (this.Passcode.indexOf(keyword) > -1){
          checkPasscode = true;
        }
      });

      if (checkPasscode){
        this.bHit = false;
        this.infoVisible = false;
        // this.socket.emit('onReady', 1);
      } else {
        this.bHit = true;
      }
    },
    onTest() {
      this.bFade = true;
      setTimeout(() => {
        this.bFade = false;
      }, 3000);
    },
    onStart() {
      if (this.startCheck){
        this.bFade = true;
        setTimeout(() => {
          this.bFade = false;
          this.qrcodeVisible = false;
          this.startVisible = true;
          const src = '/video/開場影像＿你殺了人.mp4';
          this.playVideo(src);
          this.music.src = '/music/開場影像＿你殺了人.wav'; // 更換
          this.music.loop = true;
          this.music.play();
          // setTimeout(() => {
          //   this.playSound('/sound/口白-你沒殺人，是他殺的.wav');
          // }, 3000);
          // setTimeout(() => {
          //   this.playSound('/sound/音效-擲筊01.wav');
          // }, 50000);
        }, 3000);
      } else {
        this.$alert('尚未開放，請遵循通知時段參與。', '通知', {
          confirmButtonText: '確定',
          callback: action => {
            // this.$router.push({ path: `/cp3`, query: { id: this.id }});
            // this.$message({
            // type: 'info',
            // message: `action: ${action}`,
            // });
          },
        });
      }
    },
    show() {
      this.$modal.show('login');
      // this.$modal.show('waiting');
    },
    hide() {
      this.$modal.hide('login');
    },
    onPlayerPlay(player) {
      console.log('player Play!', player);
    },
    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },
    onEnded(player) {
      console.log('onStartVideo Ended!', player);
      this.music.pause();
      this.music.currentTime = 0;

      this.$router.push({ path: `/cp1`, query: { id: this.id, passcode: this.Passcode }});
    },
    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .info {
    text-align: left;
  }

  .ok {
    margin-top: 36px;
  }

  .black {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 99999;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .info-dialog{
    ::v-deep .el-dialog {
      max-width: 800px;
    }
  }

  .question-dialog{
    ::v-deep .el-dialog__header{
      opacity: 0;
    }
  }
  .question-dialog{
    ::v-deep .el-dialog {
      background-color: transparent;
      font-size: 1.6em;
    }
  }
  .question-dialog{
    ::v-deep .el-button {
      font-size: 1.6em;
      padding: 20px;
      margin: 40px;
    }
  }
}

.passcode {
  text-align: center;
  .input{
    width: 200px;
    margin-right: 10px;
  }

  .hit{
    color: red;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
