<template lang='pug'>
.container(v-loading.fullscreen.lock='loading')
    .video-player-box(v-show='startVisible')
      video-player( ref="videoPlayer"
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @ready="onPlayerReady($event)"
                    @pause='onPause($event)'
                    @ended='onEnded($event)'
                  )
    transition(name='fade')
      .dialogue(v-show='dVisible')
        h1 精神科醫師
        el-scrollbar.textScroll(ref='textScrollbar')
          el-row.text-line(v-for='text in textArray' :key='text.id')
            vue-typer.text(v-if='bText'
                    :text='text'
                    :repeat='0'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='100'
                    :type-delay='80'
                    :pre-erase-delay='3000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='smooth'
                    @typed='onTextTyped'
                    @completed='onTextComplete'
                    )
        el-dialog.question-dialog(title='' :visible.sync="questionVisible")
          div
            el-button(type='info' @click='selectAns(0)' v-show='arrayAns[step][0] != ""') {{ arrayAns[step][0] }}
          div
            el-button(type='info' @click='selectAns(1)' v-show='arrayAns[step][1] != ""') {{ arrayAns[step][1] }}
          div
            el-button(type='info' @click='selectAns(2)' v-show='arrayAns[step][2] != ""') {{ arrayAns[step][2] }}

</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'ProsecutorA',
  components: {
    VueTyper,
  },
  data() {
    return {
      id: 0,
      passcode: '',
      loading: false,
      text: '「請等待片刻」',
      startVisible: false,
      questionVisible: false,
      dVisible: false,
      music: null,
      sound: null,
      videoIndex: 1,
      playerOptions: {
        autoplay: false,
        controls: false,
        fullscreen: true,
        muted: true,
        loop: true,
        // preload: 'auto', // 建議瀏覽器在<video>加載元素後是否應該開始下載視頻數據。auto瀏覽器選擇最佳行為,立即開始加載視頻（如果瀏覽器支持）
        fluid: true, // 當true時，Video.js player將擁有流體大小。換句話說，它將按比例縮放以適應其容器
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // sources: [
        //   {
        //     type: 'video/mp4', // 這裡的種類支持很多種：基本視頻格式、直播、流媒體等，具體可以參看git網址項目
        //     src: '', // url地址
        //   },
        // ],
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
        notSupportedMessage: '載入影片失敗，請檢查您的網路，並聯繫現場工作人員。',
      },
      ansInput: '',
      ansInputTmp: '',
      textArray: ['你好，我會問你一些問題...你只要誠實回答即可，我不是來逼你認罪的，但你要誠實回答我，好嗎？資料上說你曾經有過三個月身心科的就診的紀錄，但後來怎麼沒有回診？'],
      bText: false,
      timer: null,
      nextTimer: null,
      step: 0,
      videoSrc: '',
      soundSrc: '',
      arrayAns: [
        ['', '', ''],
        ['吃藥會讓我精神不濟', '我覺得很奇怪，我又沒有生病', ''],
        ['祂叫我不要吃的，我相信他', '那是真真實實的，只是你平凡人不相信，你們只看見你們想看見的...', ''],
        ['我覺得我的身體是拿來奉獻的', '吃那些藥沒有用啦！', ''],
        ['師父一直都在，大概從我有記憶以來，起初我確實也覺得有點怪怪的...', '我不是很確定了，大概十歲左右開始吧！', ''],
        ['對啊！一直都是同一個人', '最近這幾年開始出現另一個聲音', ''],
        ['我們滿常聊天的，但多半時候都是師父在跟我說話，有點像他在跟我說要怎麼做', '師父只有在他想出現時出現，一開始我很不習慣，覺得好像沒有隱私，但他漸漸證明祂是師父，慢慢說服我。我們還是有約定，某些時候祂不能來煩我...', ''],
        ['我沒有殺人，師父也沒有殺人', '師父沒有叫我殺他，是師父輸了', ''],
        ['', '', ''], // 8
        ['我剛剛看到師父，應該說是看到我自己在辦事', '我剛剛看到一把刀子，我拿著一把刀子在揮舞...', '不重要，我什麼也沒看到...'],
        ['對！是一把水果刀', '不對！看起來比較像是剪刀...', ''],
        ['有時候會夢到，那時候師父會跟我說話', '不常，這是第一次...', ''],
        ['多半都是跟我說哪個信徒問的事情要怎麼解決', '這是天機，我怎麼能跟你說', ''],
        ['如果師父說要懲罰，我也會照做，師父自有師父的意思，你可能不會懂，這世界沒有你想得這麼簡單...', '師父從來沒有要我去做不好的事情', ''],
        ['有一個聲音，另外一個聲音，要我殺人，他說如果我今晚不殺了他，我會活不過今晚...', '我不知道是誰...我重複過很多次，大家都說是我殺的，但我完全沒有印象。我不知道到底要重複幾次這種迴圈，黑的都被說成白的。', ''],
        ['我有說，但沒有用...', '我說過了，不是師父叫我殺人的！', ''],
        ['那聲音跟師父的聲音很像，但又有點不一樣，他脾氣比較暴躁。', '不是，是另外一個我不認識的聲音。', ''],
        ['師父沒說什麼，師父消失了，一個邪惡的力量，要我殺了他，我一直反對，但，沒用。', '他該死，他該死，他不可以這樣對我！', ''],
        ['', '', ''],
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.passcode = this.$route.query.passcode;
    console.log('query :>> ', this.id, this.passcode);
  },
  mounted() {
    this.startVisible = true;

    // 入場
    const src = '/video/精神科醫師1-1.mp4';
    this.playVideo(src);
    this.playSound('/sound/精神科醫師1-1.wav');

    // 背景BGM重複播放
    this.music = new Audio();
    this.music.pause();
    this.music.src = '/music/精神科醫師BGM.wav';
    this.music.loop = true;
    this.music.play();

    // 延遲出現對話框
    setTimeout(() => {
      this.dVisible = true;
      this.bText = true;
      this.step = 1;
    }, 5000);

    setInterval(() => {
      // console.log('Check!');
      this.$nextTick(() => {
        this.scrollDown();
      });
    }, 500);
  },

  methods: {
    onTextComplete() {
      if (this.step !== 8){
        this.questionVisible = true;
      } else if (this.step === 8) {
        this.dVisible = false;
      }
    },
    onTextTyped() {
      // this.bInputDisabled = true;
      // this.resumeVideo();
    },
    selectAns(option) {
      if (this.step === 17) {
        this.music.pause();
        this.$router.push({ path: `/cp4`, query: { id: this.id, passcode: this.passcode }});
      }
      this.questionVisible = false;
      this.ansInputTmp = this.arrayAns[this.step][option];
      // this.textArray.push(this.ansInputTmp);
      this.$nextTick(() => {
        this.scrollDown();
      });
      setTimeout(() => {
        this.checkAns();
      }, 500);
    },

    // Ans(選擇、前往編號1、文句1、前往編號2、文句2、前往編號3、文句3)
    Ans(option, step1, text1, step2, text2, step3, text3) {
      console.log('Ans / this.step :>> ', this.step);
      let selectedOption = 0;
      option.forEach((keyword, index) => {
        if (this.ansInputTmp.indexOf(keyword) > -1){
          selectedOption = index + 1;
        }
      });
      switch (selectedOption){
        case 1:
          this.textArray.push(text1);
          this.step = step1;
          break;
        case 2:
          this.textArray.push(text2);
          this.step = step2;
          break;
        case 3:
          this.textArray.push(text3);
          this.step = step3;
          break;
      }

      console.log('Ans / now step :>> ', this.step);
      this.ansInputTmp = '';
      this.checkPlayVideo();

      if (this.step === 8){
        setTimeout(() => {
          this.dVisible = true;
          this.step = 9;
          this.textArray.push('你剛剛看起來很激動，發生了什麼事，可以說給我聽嗎？');
          this.checkPlayVideo();
        }, 50000);
      }

      if (this.step === 17){
        console.log('END :>> ');
        // setTimeout(() => {
        //   this.$router.push({ path: `/cp4`, query: { id: this.id }});
        // }, 10000);
      }

      // // 電話響起
      // if (this.step === 27){
      //   console.log('END :>> ');
      //   setTimeout(() => {
      //     this.socket.emit('onCall1', this.id);
      //   }, 5000);
      // }
    },

    playVideo(source) {
      const video = {
        withCredentials: false,
        type: 'video/mp4',
        src: source,
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      setTimeout(() => {
        this.player.play();
      }, 1000);
    },

    onPlayerPlay(player) {
      console.log('player Play!', player);
    },

    onPlayerReady(player) {
      console.log('player Ready!', player);
      // this.player.play();
      // this.loading = false;
    },

    onPause(player) {
      console.log('onStartVideo Pause!', player);
    },

    onEnded(player) {
      console.log('onStartVideo Ended!', player);
    },

    playSound(path) {
      this.sound = new Audio();
      this.sound.src = path;
      this.sound.loop = false;
      this.sound.play();
    },

    reloadVideo() {
      this.playVideo(this.videoSrc);
    },

    scrollDown() {
      this.$refs.textScrollbar.wrap.scrollTop = this.$refs.textScrollbar.wrap.scrollHeight;
    },

    checkAns() {
      switch (this.step) { // TODO: 用JSON檔案外部引入
        case 1:
          this.Ans(['吃藥會讓我精神不濟', '我覺得很奇怪，我又沒有生病', 'NULL']
            , 3, '是因為吃藥會影響到你的乩童工作，才停藥的嗎？'
            , 2, '醫生當時診斷可能有幻聽及輕微妄想的現象...'
            , 0, '');
          break;
        case 2:
          this.Ans(['祂叫我不要吃的，我相信他', '那是真真實實的，只是你平凡人不相信，你們只看見你們想看見的...', 'NULL']
            , 4, '你平常都叫祂「師父」對吧！你從什麼時候開始聽到師父的聲音？'
            , 3, '是因為吃藥會影響到你的乩童工作，才停藥的嗎？'
            , 0, '');
          break;
        case 3:
          this.Ans(['我覺得我的身體是拿來奉獻的', '吃那些藥沒有用啦！', 'NULL']
            , 4, '你平常都叫祂「師父」對吧！你從什麼時候開始聽到師父的聲音？'
            , 2, '醫生當時診斷可能有幻聽及輕微妄想的現象......'
            , 0, '');
          break;
        case 4:
          this.Ans(['師父一直都在，大概從我有記憶以來，起初我確實也覺得有點怪怪的...', '我不是很確定了，大概十歲左右開始吧！', 'NULL']
            , 5, '師父一直都是同一個嗎？'
            , 6, '你平常會跟祂聊天嘛？都聊些什麼？'
            , 0, '');
          break;
        case 5:
          this.Ans(['對啊！一直都是同一個人', '最近這幾年開始出現另一個聲音', 'NULL']
            , 6, '你平常會跟祂聊天嘛？都聊些什麼？'
            , 7, '所以是師父叫你殺他的嗎？'
            , 0, '');
          break;
        case 6:
          this.Ans(['我們滿常聊天的，但多半時候都是師父在跟我說話，有點像他在跟我說要怎麼做', '師父只有在他想出現時出現，一開始我很不習慣，覺得好像沒有隱私，但他漸漸證明祂是師父，慢慢說服我。我們還是有約定，某些時候祂不能來煩我...', 'NULL']
            , 7, '所以是師父叫你殺他的嗎？'
            , 8, ' '
            , 0, '');
          break;
        case 7:
          this.Ans(['我沒有殺人，師父也沒有殺人', '師父沒有叫我殺他，是師父輸了', 'NULL']
            , 8, ' '
            , 4, '你平常都叫祂「師父」對吧！你從什麼時候開始聽到師父的聲音？'
            , 0, '');
          break;
        case 8:
          // VIDEO
          break;
        case 9:
          this.Ans(['我剛剛看到師父，應該說是看到我自己在辦事', '我剛剛看到一把刀子，我拿著一把刀子在揮舞...', '不重要，我什麼也沒看到...']
            , 11, '你常常突然看到這些畫面嗎？'
            , 10, '什麼樣的刀子？一把水果刀嗎？還是？'
            , 8, ' ');
          break;
        case 10:
          this.Ans(['對！是一把水果刀', '不對！看起來比較像是剪刀...', 'NULL']
            , 11, '你常常突然看到這些畫面嗎？'
            , 8, ' '
            , 0, '');
          break;
        case 11:
          this.Ans(['有時候會夢到，那時候師父會跟我說話', '不常，這是第一次...', 'NULL']
            , 12, '師父跟你說什麼？'
            , 13, '所以師父叫你做的事情你都會去做嗎？...包括懲罰某些人？'
            , 0, '');
          break;
        case 12:
          this.Ans(['多半都是跟我說哪個信徒問的事情要怎麼解決', '這是天機，我怎麼能跟你說', 'NULL']
            , 13, '所以師父叫你做的事情你都會去做嗎？...包括懲罰某些人？'
            , 14, '所以不是師父叫你殺人嗎？'
            , 0, '');
          break;
        case 13:
          this.Ans(['如果師父說要懲罰，我也會照做，師父自有師父的意思，你可能不會懂，這世界沒有你想得這麼簡單...', '師父從來沒有要我去做不好的事情', 'NULL']
            , 15, '你當時沒有跟師父說，如果你做了這件事情...可能就沒有辦法再服務師父了？'
            , 14, '所以不是師父叫你殺人嗎？'
            , 0, '');
          break;
        case 14:
          this.Ans(['有一個聲音，另外一個聲音，要我殺人，他說如果我今晚不殺了他，我會活不過今晚...', '我不知道是誰...我重複過很多次，大家都說是我殺的，但我完全沒有印象。我不知道到底要重複幾次這種迴圈，黑的都被說成白的。', 'NULL']
            , 16, '什麼聲音？不是師父的聲音？'
            , 17, '你先深呼吸，試著把你的思緒在理一次給我聽...師父說了些什麼？'
            , 0, '');
          break;
        case 15:
          this.Ans(['我有說，但沒有用...', '我說過了，不是師父叫我殺人的！', 'NULL']
            , 17, '你先深呼吸，試著把你的思緒在理一次給我聽...師父說了些什麼？'
            , 14, '所以不是師父叫你殺人嗎？'
            , 0, '');
          break;
        case 16:
          this.Ans(['那聲音跟師父的聲音很像，但又有點不一樣，他脾氣比較暴躁。', '不是，是另外一個我不認識的聲音。', 'NULL']
            , 15, '你當時沒有跟師父說，如果你做了這件事情...可能就沒有辦法再服務師父了？'
            , 17, '你先深呼吸，試著把你的思緒在理一次給我聽...師父說了些什麼？'
            , 0, '');
          break;
        case 17:
          // END
          break;
        case 18:
          // END
          break;
      }
    },

    checkPlayVideo() {
      this.videoSrc = '';
      this.soundSrc = '';
      switch (this.step) {
        case 1:
          this.videoSrc = '/video/精神科醫師1-1.mp4';
          // this.playVideo(this.videoSrc);
          break;
        case 2:
          this.videoSrc = '/video/精神科醫師2-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 3:
          this.videoSrc = '/video/精神科醫師2-1.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 4:
          this.videoSrc = '/video/精神科醫師2-1.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-1.wav';
          this.playSound(this.soundSrc);
          break;
        case 5:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 6:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 7:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 8:
          this.videoSrc = '/video/奇異畫面.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/奇異畫面.wav';
          this.playSound(this.soundSrc);
          break;
        case 9:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 10:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-2.wav';
          this.playSound(this.soundSrc);
          break;
        case 11:
          this.videoSrc = '/video/精神科醫師2-2.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 12:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 13:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 14:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          this.soundSrc = '/sound/精神科醫師2-3.wav';
          this.playSound(this.soundSrc);
          break;
        case 15:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 16:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
        case 17:
          this.videoSrc = '/video/精神科醫師2-3.mp4';
          this.playVideo(this.videoSrc);
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>

.container {
  background-image: url('/img/bg.jpeg');
  height: 100vh;
  width: 100vw;
  .qrcode {
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }

  .video-player-box {
    width: 100%;
  }

  .vjs-custom-skin {
    ::v-deep .video-js {
      width: 100vw !important;
      height: 100vh !important;
    }
  }

  .el-scrollbar {
    height: 52vh;
  }

  .textScroll {
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__view {
      height: 10%;
    }
  }

  .text-line {
    border-bottom: inset;
  }

  .dialogue {
    width: 30vw;
    height: 82vh;
    background-color: white;
    z-index: 9999;
    position: fixed;
    right: 3vw;
    top: 3vh;
    padding: 20px;
    border-radius: 5px;
    overflow: auto;
    overflow-x: hidden;
    opacity: 0.9;

    .text-input {
      width: 90%;
      position: absolute;
      bottom: 2%;
    }

    .text{
      font-size: 1.5em;
      text-align: left;
      float: left;
      margin-top: 20px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question-dialog{
  position: fixed;
  width: 30vw;
  left: 66vw;
  padding-top: 65vh;
  ::v-deep .el-dialog__header{
    opacity: 0;
  }
  ::v-deep .el-dialog__body{
    text-align: right;
    padding: 0;
  }
}

.question-dialog{
  ::v-deep .el-dialog {
    background-color: transparent;
    font-size: 1.6em;
    box-shadow: none;
    margin-top: 0vh !important;
    // margin-right: 5vw;
    width: 100%;
  }
}
.question-dialog{
  ::v-deep .el-button {
    font-size: 1.6em;
    padding: 10px;
    margin: 10px;
    white-space: break-spaces;
    text-align: left;
  }
}
</style>
